import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import PME from '../../assets/svg/supporters/pme.svg'
import YUL from '../../assets/svg/supporters/yul.svg'
import JCCM from '../../assets/svg/supporters/jccm.svg'
import StAnne from '../../assets/svg/supporters/stAnne.svg'
import Desjardins from '../../assets/svg/supporters/desjardins.svg'
import Futurpreneur from '../../assets/svg/supporters/futurpreneur.svg'

const LogoGridCard = ({ label }) => {
    const { t } = useTranslation('misc')
    return (
        <div className="card logoGridCard">
            <h3 className="cardTitle">{label || t('logoGridTitle')}</h3>
            <div className="logoGrid">
                <YUL />
                <StAnne />
                <Desjardins />
                <Futurpreneur />
                <JCCM />
                <PME />
            </div>
        </div>
    )
}

export default LogoGridCard

LogoGridCard.propTypes = {
    label: PropTypes.string,
}

LogoGridCard.defaultProps = {
    label: '',
}
